import { Stop } from '../../domain/stop/Stop';
import { TableColumn, parseDefaultTableColumns } from '../shared/tableColumns';

export const stopsTableColumnKeys = [
  `status`,
  `type`,
  `vehicle_label`,
  `estimated_arrival_time`,
  `estimated_departure_time`,
  `planned_arrival_time`,
  `planned_departure_time`,
  `pin`,
  `additional_info`,
  `customer_text_field`,
  `execution_date`,
  `client_external_id`,
  `label`,
  `external_id`,
  `location`,
  `location_details`,
  `comments`,
  `reference_person`,
  `phone`,
  `email`,
  `url`,
  `weight`,
  `volume`,
  `time_windows`,
  `requires`,
  `cluster`,
  `reward`,
  `duration`,
  `max_delivery_time`,
  `report_location`,
  `report_created_at`,
  `report_comments`,
  `report_signature`,
  `report_images`,
  `report_cancel_reason`,
  `survey`,
  `customers_link`,
  `created_at`,
] as const;

export type StopsTableColumnKeys = (typeof stopsTableColumnKeys)[number];

export type StopsTableColumn = TableColumn<StopsTableColumnKeys, Stop>;

export const STOPS_DEFAULT_TABLE_COLUMNS: Array<StopsTableColumn> = [
  // icon: true,
  {
    identifier: `label`,
    type: `string`,
    displayable: true,
    filterable: true,
    sortable: true,
  },
  {
    identifier: `status`,
    type: `string`,
    displayable: true,
    filterable: true,
    sortable: false,
  },
  {
    identifier: `type`,
    type: `string`,
    displayable: true,
    filterable: true,
    sortable: false,
  },
  {
    identifier: `location`,
    type: `Location`,
    displayable: true,
    filterable: true,
    sortable: false,
  },
  {
    identifier: `location_details`,
    type: `string`,
    displayable: true,
    filterable: true,
    sortable: false,
  },
  {
    identifier: `comments`,
    type: `string`,
    displayable: true,
    filterable: true,
    sortable: false,
  },
  {
    identifier: `execution_date`,
    type: `Date`,
    displayable: true,
    filterable: true,
    sortable: false,
  },
  {
    identifier: `client_external_id`,
    type: `string`,
    displayable: true,
    filterable: true,
    sortable: false,
  },
  {
    identifier: `estimated_arrival_time`,
    type: `Date`,
    displayable: true,
    filterable: true,
    sortable: true,
  },
  {
    identifier: `estimated_departure_time`,
    type: `Date`,
    displayable: true,
    filterable: true,
    sortable: true,
  },
  {
    identifier: `planned_arrival_time`,
    type: `number`,
    displayable: true,
    filterable: true,
    sortable: false,
  },
  {
    identifier: `planned_departure_time`,
    type: `number`,
    displayable: true,
    filterable: true,
    sortable: false,
  },
  {
    identifier: `pin`,
    type: `string`,
    displayable: true,
    filterable: true,
    sortable: false,
  },
  {
    identifier: `additional_info`,
    type: `string`,
    displayable: true,
    filterable: true,
    sortable: false,
  },
  {
    identifier: `customer_text_field`,
    type: `string`,
    displayable: true,
    filterable: true,
    sortable: false,
  },
  {
    identifier: `vehicle_label`,
    type: `string`,
    displayable: true,
    filterable: true,
    sortable: false,
  },
  {
    identifier: `duration`,
    type: `number`,
    displayable: true,
    filterable: true,
    sortable: false,
  },
  {
    identifier: `weight`,
    type: `number`,
    displayable: true,
    filterable: true,
    sortable: false,
  },
  {
    identifier: `volume`,
    type: `number`,
    displayable: true,
    filterable: true,
    sortable: false,
  },
  {
    identifier: `time_windows`,
    type: `Array`,
    displayable: true,
    filterable: true,
    sortable: false,
  },
  {
    identifier: `requires`,
    type: `Array`,
    displayable: true,
    filterable: true,
    sortable: false,
  },
  {
    identifier: `cluster`,
    type: `string`,
    displayable: true,
    filterable: true,
    sortable: false,
  },
  {
    identifier: `reward`,
    type: `string`,
    displayable: true,
    filterable: true,
    sortable: false,
  },
  {
    identifier: `max_delivery_time`,
    type: `number`,
    displayable: true,
    filterable: true,
    sortable: false,
  },
  {
    identifier: `external_id`,
    type: `string`,
    displayable: true,
    filterable: true,
    sortable: false,
  },
  {
    identifier: `reference_person`,
    type: `string`,
    displayable: true,
    filterable: true,
    sortable: false,
  },
  {
    identifier: `email`,
    type: `string`,
    displayable: true,
    filterable: true,
    sortable: false,
  },
  {
    identifier: `url`,
    type: `string`,
    displayable: true,
    filterable: true,
    sortable: false,
  },
  {
    identifier: `phone`,
    type: `string`,
    displayable: true,
    filterable: true,
    sortable: false,
  },
  {
    identifier: `report_location`,
    type: `Location`,
    displayable: true,
    filterable: true,
    sortable: false,
  },
  {
    identifier: `report_created_at`,
    type: `Date`,
    displayable: true,
    filterable: true,
    sortable: false,
  },
  {
    identifier: `report_comments`,
    type: `string`,
    displayable: true,
    filterable: true,
    sortable: false,
  },
  {
    identifier: `report_signature`,
    type: `boolean`,
    displayable: true,
    filterable: true,
    sortable: false,
  },
  {
    identifier: `report_images`,
    type: `boolean`,
    displayable: true,
    filterable: true,
    sortable: false,
  },
  {
    identifier: `report_cancel_reason`,
    type: `string`,
    displayable: true,
    filterable: true,
    sortable: false,
  },
  {
    identifier: `survey`,
    type: `string`,
    displayable: true,
    filterable: true,
    sortable: false,
  },
  {
    identifier: `customers_link`,
    type: `string`,
    displayable: true,
    filterable: true,
    sortable: false,
  },
  {
    identifier: `created_at`,
    type: `Date`,
    displayable: true,
    filterable: true,
    sortable: true,
  },
];

export const getStopsTableColumns = (): Array<StopsTableColumn> => {
  const storedTableColumns = localStorage.getItem(`STOPS_TABLE_COLUMNS`);
  if (!storedTableColumns) return STOPS_DEFAULT_TABLE_COLUMNS;

  const parsedTableColumns = JSON.parse(storedTableColumns);

  // Final table columns should have the current ones.
  const finalTableColumns: Array<StopsTableColumn> = [...(Array.isArray(parsedTableColumns) ? parsedTableColumns : [])];

  return parseDefaultTableColumns({
    finalTableColumns,
    defaultTableColumns: STOPS_DEFAULT_TABLE_COLUMNS,
  });
};

export const setStopsTableColumns = (tableColumns: Array<StopsTableColumn>) => {
  localStorage.setItem(`STOPS_TABLE_COLUMNS`, JSON.stringify(tableColumns));
};
