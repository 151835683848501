import { isValidPhoneNumber, parsePhoneNumberWithError as numberParser, PhoneNumber } from 'libphonenumber-js';

export function validatePhoneNumber(number: string) {
  // String must start with "+"
  if (/^\+./.test(number)) {
    return isValidPhoneNumber(number);
  }
  return false;
}

export function parsePhoneNumber(number: string): PhoneNumber | undefined {
  try {
    return numberParser(number);
  } catch (error) {
    console.warn(error);
    return undefined;
  }
}

export const formatPhoneNumber = ({ phone, dialCode }: { phone: string; dialCode: string }) => {
  // Removes the + symbol
  const newPhone = (phone ?? ``).replace(/[^\d]+/g, ``);
  if (newPhone === dialCode) {
    return ``;
  }

  if (newPhone === `` && !validatePhoneNumber(newPhone)) {
    return ``;
  }

  return `+${newPhone}`;
};

export const obfuscatePhoneNumber = (phone: string) => {
  return phone.slice(-3).padStart(8, `*`);
};
