import { PopoverOrigin } from '@mui/material';
import _ from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { RoutalPalette } from './Colors';
import { RoutalSearchInput } from './RoutalSearchInput';
import { RoutalSelect, RoutalSelectMultiSelectOption, RoutalSelectProps } from './RoutalSelect';
import { RoutalText } from './RoutalText';

export interface RoutalSelectAutocompleteMultiSelectOption extends RoutalSelectMultiSelectOption {
  searchText?: string;
  notSearchable?: boolean;
}

export type RoutalSelectAutocompleteMultiSelectOptions = Array<RoutalSelectAutocompleteMultiSelectOption | null>;

export interface RoutalSelectAutocompleteProps extends Omit<RoutalSelectProps, `popoverProps`> {
  autocompleteId?: string;
  searchValue?: string;
  searchWidth?: number;
  searchPlaceholder?: string;
  searchEmptyText?: string;
  popoverProps: {
    options: RoutalSelectAutocompleteMultiSelectOptions;
    maxHeight?: string;
    anchor?: {
      anchorOrigin?: PopoverOrigin;
      transformOrigin?: PopoverOrigin;
    };
    onOpen?: (event: any) => void;
    onBlur?: (event: any) => void;
  };
}

const generateRandomId = () => `routal-select-autocomplete-input-${Math.random()}`;

export const RoutalSelectAutocomplete = ({
  autocompleteId,
  searchValue,
  searchWidth,
  searchPlaceholder,
  searchEmptyText,
  popoverProps,
  ...routalSelectProps
}: React.PropsWithChildren<RoutalSelectAutocompleteProps> & React.HTMLAttributes<RoutalSelectAutocompleteProps>) => {
  const keyRef = useRef<string>(autocompleteId ?? generateRandomId());

  useEffect(() => {
    if (keyRef.current === null && keyRef.current !== autocompleteId) {
      keyRef.current = autocompleteId ?? generateRandomId();
    }
  }, [autocompleteId]);

  const [searchText, setSearchText] = useState<string>(searchValue ?? ``);
  const [options, setOptions] = useState(popoverProps.options);

  useEffect(() => {
    if ((searchText ?? ``) === ``) {
      setOptions(popoverProps.options);
      return;
    }
    const filteredOptions = popoverProps.options.filter((option) => {
      if (option === null || option.notSearchable || !option.searchText) return false;

      return option.searchText
        .normalize(`NFD`)
        .replace(/[\u0300-\u036f]/g, ``)
        .toLowerCase()
        .includes(
          searchText
            .normalize(`NFD`)
            .replace(/[\u0300-\u036f]/g, ``)
            .toLowerCase()
        );
    });
    setOptions(filteredOptions);
  }, [popoverProps.options, searchText]);

  return (
    <RoutalSelect
      {...routalSelectProps}
      popoverProps={{
        ...popoverProps,
        onOpen: (event: any) => {
          const input = document.getElementById(keyRef.current);
          if (input) {
            window.setTimeout(() => input.focus(), 0);
          }
          popoverProps.onOpen && popoverProps.onOpen(event);
        },
        onBlur: (event: any) => {
          setSearchText(``);
          popoverProps.onBlur && popoverProps.onBlur(event);
        },
        options: [
          {
            key: `autocomplete-search`,
            width: searchWidth,
            listItemStyles: {
              padding: `8px`,
              height: `52px`,
              backgroundColor: RoutalPalette.oldWhite,
              alignItems: `flex-start`,
            },
            content: (
              <RoutalSearchInput
                id={keyRef.current}
                fieldValue={searchText}
                placeholder={searchPlaceholder}
                inputContainerStyles={{
                  width: `100%`,
                }}
                onChange={_.debounce((text: any) => {
                  setSearchText(text);
                }, 300)}
                searchLive
              />
            ),
            closeOnClick: false,
            onClick: () => {},
          },
          ...(options.length > 0
            ? options
            : [
                {
                  key: `no-search-results`,
                  width: searchWidth,
                  content: <RoutalText>{searchEmptyText}</RoutalText>,
                  onClick: () => {},
                },
              ]),
        ],
      }}
    />
  );
};
