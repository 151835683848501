import { SvgIcon } from '@mui/material';
import { RoutalPalette } from '@nx-smartmonkey/ui';
import React from 'react';

interface ListViewProps {
  color?: string;
  isSelected?: boolean;
  style?: React.CSSProperties;
}

export const ListView = ({
  color = RoutalPalette.primary20,
  isSelected = false,
  style = {},
  ...props
}: ListViewProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 21 15" style={{ width: `21px`, height: `15px`, ...style }}>
      <g>
        <path
          d="M6.5 1.5H19.5"
          stroke={isSelected ? `white` : color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.5 7.5H19.5"
          stroke={isSelected ? `white` : color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.5 13.5H19.5"
          stroke={isSelected ? `white` : color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M1.5 1.5H1.51"
          stroke={isSelected ? `white` : color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M1.5 7.5H1.51"
          stroke={isSelected ? `white` : color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M1.5 13.5H1.51"
          stroke={isSelected ? `white` : color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
};
