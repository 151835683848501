import { CustomFields } from '../customFields/CustomFields';
import { ValueObject } from '../ValueObject';

export type ReportTaskStatus = `completed` | `canceled` | `pending`;

export interface ReportTaskProps {
  task_id: string;
  status: ReportTaskStatus;
  comments?: string;
  custom_fields?: CustomFields;
}

export class ReportTask extends ValueObject<ReportTaskProps> {
  get value(): ReportTaskProps {
    return this.props;
  }
  get task_id(): string {
    return this.props.task_id;
  }
  get status(): ReportTaskStatus {
    return this.props.status;
  }
  get comments(): string | undefined {
    return this.props.comments;
  }
  get custom_fields(): CustomFields | undefined {
    return this.props.custom_fields;
  }
  private constructor(props: ReportTaskProps) {
    super(props);
  }

  static create(props: ReportTaskProps): ReportTask {
    return new ReportTask(props);
  }
}
